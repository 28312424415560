const isBrowser = typeof window !== 'undefined';
/**
 * This class displays a toast momentarily
 */

export class Toast {
	constructor (message = '', type = '', display = '') {
		if (isBrowser) {
			this.message = message;
			this.type = type;
			this.display = display;
			this.toastElement = document?.createElement('div');
			this.toastElement.className = 'id';
		}
	}

	assign () {
		this.toastElement.className = 'toast';

		switch (this.display) {
			case 'top': this.toastElement.classList.add('top'); break;
			case 'bot': this.toastElement.classList.add('bot'); break;
		}

		// success, warning, error, alert, info, dark
		this.toastElement.classList.add(this.type);
		this.toastElement.textContent = this.message;

		document.body.appendChild(this.toastElement);

		this.show();
	}

	show () {
		this.toastElement.style.display = 'block';
		setTimeout(() => {
			this.hide();
		}, 3000);
	}

	hide () {
		this.toastElement.style.display = 'none';
	}
}
