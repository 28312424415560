/* eslint-disable */
!(function() {
    var arrayConstructor = [].constructor || Array // for legacy browsers before IE8 & Android4.4 https://caniuse.com/mdn-javascript_builtins_object_constructor
    function isGlobal(object) {return object.Array == arrayConstructor}

    if (typeof globalThis == "object" && isGlobal(globalThis)) return

    var tmpGlobal
      = typeof window == "object" && isGlobal(window) ? window // Browser
      : typeof global == "object" && isGlobal(global) ? global // NodeJS
      : typeof self == "object"   &&  isGlobal(self)  ?  self  // Worker
      : typeof this == "object"   &&  isGlobal(this)  ?  this  // Other?
      : Function("return this")() // works when unsafe-eval is allowed

    tmpGlobal.globalThis = tmpGlobal
})()
/* eslint-enable */
