import '../plugins/globalThis';
import { dateAddWeeks, dateFormatYMD } from './dateFns.js';
import { Base64 } from '../plugins/base64.js';
import { Toast } from '@js/components/toast.js';
import { ConnectApi } from '@js/plugins/connectApi';

const isBrowser = typeof window !== 'undefined';

export const isInternetExplorer = (globalThis?.navigator?.userAgent?.match?.(/MSIE|Trident/) !== null);

globalThis.doc = isBrowser ? document : null;

// Ensure that globalThis.js_params is defined
if (!globalThis.js_params) {
	globalThis.js_params = {};
}

export const EventDispatcher = new Dispatcher();

export function Dispatcher () {
	return {
		addEventListener (event, callback) {
			return document?.addEventListener?.(event, (evt) => {
				callback.apply(this, evt.detail);
			});
		},

		dispatch (event) {
			document?.dispatchEvent?.(createCustomEvent(event, Array.prototype.slice.call(arguments, 1)));
		}
	};
}


/**
 * Sets the dbg cookie
 * @param {Boolean} shouldOpen
 */
export function debugMenu (shouldOpen = 1) {
	shouldOpen = 1 * shouldOpen;
	const cookieName = 'dbg';
	if (!shouldOpen) {
		eraseCookie(cookieName);
		storedItemCreate('debug_menu_state', shouldOpen);
		return;
	}
	storedItemCreate('debug_menu_state', shouldOpen);
	setCookie(cookieName, shouldOpen);
	location.reload();
}

/**
 * Sets the force_server cookie
 * @param {Number} serverId
 */
export function forceServer (serverId) {
	const cookieName = 'force_server';
	if (serverId === null) {
		eraseCookie(cookieName);
		return;
	}
	setCookie(cookieName, serverId);
}


/** Create custom event in the appropriate format
	 @param  name        string - the event name
	 @param  data        string or object - the data content of the event
	 @param  bubbles     boolean - set to false if the event should not bubble up (default: true)
	 @param  cancelable  boolean - set to false if the event should not be cancelable (default: true)

	 @return the created event **/
export function createCustomEvent (name, data = null, bubbles = true, cancelable = true) {
	// If the 'CustomEvent' constructor is not supported,
	// fall back to the 'createEvent' method.
	if (typeof CustomEvent === 'function') {
		return new CustomEvent(name, { detail: data, bubbles, cancelable });
	} else {
		const event = document?.createEvent('CustomEvent');
		event.initCustomEvent(name, bubbles, cancelable, data);

		return event;
	}
}

/**
 * Create a reactive property
 * @param {name} name - variable name
 * @param {initialValue} initialValue - the value to set to the variable
 * @param {updateCallback} updateCallback - the function to call when the variable has been updated
 * @param {context} context - where to bind the variable
 */
export function defineReactiveProperty (name, initialValue, updateCallback, context = this) {
	let currentValue = initialValue;
	Object.defineProperty(context, name, {
		get () {
			return currentValue;
		},
		set (newValue) {
			// Only run callback if value has changed, need better comparison to handle objects
			if (currentValue !== newValue) {
				console.log('value set and changed');
				currentValue = newValue;
				updateCallback();
			} else {
				console.log('value set but not changed');
			}
		}
	});
	updateCallback();
}

/**
 * Get the index of an element
 * @param {Element} child
 * @returns {number}
 */
export const getNodeIndex = child => [...child.parentNode.children].findIndex(c => c == child);

/**
 * Returns the height of the document
 * @returns {number}
 */
export function getDocumentHeight () {
	const body = document.body;
	const html = document.documentElement;
	return Math.max(
		body.offsetHeight,
		body.scrollHeight,
		html.clientHeight,
		html.offsetHeight,
		html.scrollHeight
	);
}

/**
 * Get the properly formatted tracking class name
 * @param {string} className the core class name
 * @returns the formatted class name
 */
export function getTrackingClass (className) {
	return `trck_${className}`;
}

/**
 * Get all children element preceding the given child element
 * @param {Element} element DOM element - the child element
 * @returns the list of all preceding siblings
 */
export function prevAll (element) {
	const result = [];

	while ((element = element.previousElementSibling)) {
		result.push(element);
	}

	return result;
}


/**
 * Check if currently on the homepage
 * @returns {boolean} True if body contains the class "home"
 */
export function isHomePage () {
	return document.body.classList.contains('home');
}

/**
 * Check if currently on the showresult page
 * @returns {boolean} True if body contains the class "showresult"
 */
export function isShowresultPage () {
	return !!globalThis.js_params.isShowresult;
}

/**
 * Used to check if mobile
 * @returns {boolean} true if window innerWidth is less than 768
 */
export function isMobileView () {
	return globalThis.innerWidth < 768;
}

export function isTabletView () {
	return globalThis.innerWidth >= 768 && globalThis.innerWidth < 992;
}

/**
 * Used to check if desktop
 * @returns {boolean} true if window innerWidth is greater that or equal to 992
 */
export function isDesktopView () {
	return globalThis.innerWidth >= 992;
}

/**
 * Check if currently fullscreen
 * @returns {boolean} True if body contains the class "no-scroll"
 */
export function isFullScreen () {
	return document.body.classList.contains('no-scroll');
}

/**
 * Add 7 days
 * @param {string} strDate
 * @returns {string} Formatted "yyyy-mm-dd"
 */
export function add7Days (strDate) {
	return dateFormatYMD(dateAddWeeks(new Date(strDate), 1));
}

/**
 * Add 14 days
 * @param {string} strDate
 * @returns {string} Formatted "yyyy-mm-dd"
 */
export function add14Days (strDate) {
	return dateFormatYMD(dateAddWeeks(new Date(strDate), 2));
}

/**
 * Transform an array of object into an array of strings
 * @param {array} arr
 * @returns {array}
 */
export function multiDimensionalUnique (arr) {
	const uniques = [];
	const itemsFound = {};
	const arrLength = arr.length;

	for (let i = 0; i < arrLength; i++) {
		const stringified = JSON.stringify(arr[i]);

		if (itemsFound[stringified]) {
			continue;
		}

		uniques.push(arr[i]);

		itemsFound[stringified] = true;
	}

	return uniques;
}

/**
 * Shuffle an array
 * @param {array} array Array to shuffle
 * @returns {array} New array shuffled
 */
export function shuffleArray (arr) {
	return arr
		.map(a => [Math.random(), a])
		.sort((a, b) => a[0] - b[0])
		.map(a => a[1]);
}

/**
 * Check if Safari
 * @returns {boolean} True if vendor or user agent matches the correct parameters
 */
export function isSafari () {
	return (
		navigator.vendor
		&& navigator.vendor.includes('Apple')
		&& navigator.userAgent
		&& !navigator.userAgent.includes('CriOS')
		&& !navigator.userAgent.includes('FxiOS')
	);
}

/**
 * Check if email is valid using regex
 * @param {string} email Email address to validate
 * @returns {boolean}
 */
export function isValidEmail (email) {
	return email.search(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) != -1;
}

/**
 * Prepend a zero for numbers lower then 10
 * @param {string|number} num
 * @returns {string}
 */
export function addZero (num) {
	return ('0' + num).slice(-2);
}

/**
 * Get a cookie value
 * @param {string} name Cookie name to get
 * @returns {string|null}
 */
export function getCookie (name) {
	const nameEQ = name + '=';
	const ca = document.cookie.split(';');

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];

		while (c.charAt(0) == ' ') {
			c = c.substring(1, c.length);
		}

		if (c.indexOf(nameEQ) === 0) {
			return c.substring(nameEQ.length, c.length);
		}
	}

	return null;
}

/**
 * Set a cookie value
 * @param {string} name Name of cookie value to set
 * @param {string|number} value Value to set
 * @param {string|number} days Number of days the cookie should be valid
 * @param {string|number} seconds Number of days the cookie should be valid
 */
export function setCookie (name, value, days, seconds) {
	let expires = '';

	if (days) {
		const date = new Date();

		seconds = seconds ? seconds * 1000 : 0;
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000 + seconds));

		expires = '; expires=' + date.toGMTString();
	}

	document.cookie = name + '=' + value + expires + '; path=/';
}

/**
 * Remove a cookie
 * @param {string} name Cookie to remove
 */
export function eraseCookie (name) {
	setCookie(name, '', -1);
}

/**
 * Get URL parameters
 * @returns {string}
 */
export function getUrlVars (url = globalThis.location.href) {
	// const urlParams = new URLSearchParams(location.search);
	// const params = Object.fromEntries(urlParams);
	// return params
	const vars = {};
	url.replace(/[?&]+([^=&]+)=([^&|#]*)/gi, function (
		m,
		key,
		value
	) {
		vars[key] = value;
	});

	return vars;
}

/**
 * Update current URL with new parameters
 * @param {string} key
 * @param {string} value
 * @param {boolean} pushState - If true, use a new history entry will be created
 */
export function urlParameterSet (key, value, pushState = false) {
	try {
		if (!isBrowser) {
			return;
		}
		const url = new URL(globalThis.location.href);
		url.searchParams.set(key, value);
		if (pushState) {
			globalThis.history.pushState({}, '', url);
		} else {
			globalThis.history.replaceState({}, '', url);
		}
		// update url without updating the history
	} catch (error) {
		console.warn('Error updating URL parameter', error);
	}
}

/**
 *
 * @param {string} key
 * @param {boolean} pushState - If true, use a new history entry will be created
 */
export function urlParameterDelete (key, pushState) {
	try {
		if (!isBrowser) {
			return;
		}
		const url = new URL(globalThis.location.href);
		url.searchParams.delete(key);
		if (pushState) {
			globalThis.history.pushState({}, '', url);
		} else {
			globalThis.history.replaceState({}, '', url);
		}
	} catch (error) {
		console.warn('Error deleting URL parameter', error);
	}
}

export function urlParameterGet (key) {
	if (!isBrowser) {
		return;
	}
	const url = new URL(globalThis.location.href);
	return url.searchParams.get(key);
}

/**
 * Set a URL parameter
 * @param {string|number} url full URL string
 * @param {string|number} parameterName parameter name
 * @param {string|number} parameterValue parameter value to set
 * @param {boolean} atStart if the property should be added to the start of the url
 * @returns {string} full URL with the new parameter
 */
export function setUrlParameter (url, parameterName, parameterValue, atStart) {
	const replaceDuplicates = true;
	let urlhash;
	let cl;
	if (url.indexOf('#') > 0) {
		cl = url.indexOf('#');
		urlhash = url.substring(url.indexOf('#'), url.length);
	} else {
		urlhash = '';
		cl = url.length;
	}

	const sourceUrl = url.substring(0, cl);

	const urlParts = sourceUrl.split('?');
	let newQueryString = '';

	if (urlParts.length > 1) {
		const parameters = urlParts[1].split('&');

		for (let i = 0; i < parameters.length; i++) {
			const parameterParts = parameters[i].split('=');

			if (!(replaceDuplicates && parameterParts[0] === parameterName)) {
				if (newQueryString === '') {
					newQueryString = '?';
				} else {
					newQueryString += '&';
				}

				newQueryString
					+= parameterParts[0]
					+ '='
					+ (parameterParts[1] ? parameterParts[1] : '');
			}
		}
	}

	if (newQueryString === '') {
		newQueryString = '?';
	}

	if (atStart) {
		newQueryString
			= '?'
			+ parameterName
			+ '='
			+ parameterValue
			+ (newQueryString.length > 1 ? '&' + newQueryString.substring(1) : '');
	} else {
		if (newQueryString !== '' && newQueryString !== '?') {
			newQueryString += '&';
		}

		newQueryString
			+= parameterName + '=' + (parameterValue ? parameterValue : '');
	}

	return urlParts[0] + newQueryString + urlhash;
}

export function range (start, end, step) {
	const range_ = [];
	const typeofStart = typeof start;
	const typeofEnd = typeof end;

	if (step === 0) {
		throw new TypeError('Step cannot be zero.');
	}

	if (typeofStart == 'undefined' || typeofEnd == 'undefined') {
		throw new TypeError('Must pass start and end arguments.');
	} else if (typeofStart != typeofEnd) {
		throw new TypeError('Start and end arguments must be of same type.');
	}

	typeof step === 'undefined' && (step = 1);

	if (end < start) {
		step = -step;
	}

	if (typeofStart == 'number') {
		while (step > 0 ? end >= start : end <= start) {
			range_.push(start);
			start += step;
		}
	} else if (typeofStart == 'string') {
		if (start.length != 1 || end.length != 1) {
			throw new TypeError('Only strings with one character are supported.');
		}

		start = start.charCodeAt(0);
		end = end.charCodeAt(0);

		while (step > 0 ? end >= start : end <= start) {
			range_.push(String.fromCharCode(start));
			start += step;
		}
	} else {
		throw new TypeError('Only string and number types are supported');
	}

	return range_;
}

/**
 * Converts seconds into hours and minutes
 * @param {Number} traveltime - the traveltime in seconds
 * @returns {String} the traveltime in hours and minutes
 */
export function getTimeText (traveltime) {
	if (
		typeof traveltime === 'number'
		&& traveltime != 0
		&& traveltime != Number.MAX_VALUE
	) {
		const time = String(traveltime / 60 / 60);
		const timeArr = time.split('.');
		let minutes = '';

		// in german formatting, add space before hours and minutes
		const germanSpacing = globalThis.js_params.language == 'de' ? ' ' : '';

		if (timeArr.length == 2) {
			minutes
			= ' '
			+ Math.round(Number('0.' + String(timeArr[1])) * 60)
			+ germanSpacing
			+ l('time.minutesShort');
		}

		const hours = timeArr[0];

		return hours + germanSpacing + l('time.hoursShort') + minutes;
	}

	return l('Saknas');
}

/** Formats a price based on formating options (if none or only a few is provided, fallback to settings file)
	 @param int price
	 @param object formating

	 @return string **/
export function lc (price, formating) {
	if (typeof formating === 'undefined' || formating === true) {
		formating = {};
	}

	for (const key in globalThis.js_params.defaultCurrencyFormating) {
		if (!formating.hasOwnProperty(key)) {
			formating[key] = globalThis.js_params.defaultCurrencyFormating[key];
		}
	}

	price = Number(price);

	// Now setup everything
	price = price.toFixed(formating.decimals);

	// Decimal sign
	if (formating.decimalSymbol !== '.') {
		price = price.replace('.', formating.decimalSymbol);
	}

	// Thousand seperators
	price = price
		.toString()
		.replace(
			/(\d)(?=(\d{3})+(?!\d))/g,
			'$1' + formating.thousandSeperator
		);

	// Currency-symbol spacing
	const posBefore = formating.symbolPositionBefore;
	const currencySpace = formating.symbolSpace;
	let preString = '';
	let postString = '';

	// Assign space and currencysymbol to correct place
	if (posBefore) {
		preString = formating.symbol + (currencySpace ? ' ' : '');
	} else {
		postString = (currencySpace ? ' ' : '') + formating.symbol;
	}

	return preString + price + postString;
}

/**
 *
 * @param {String} path to file
 * @param{Boolean} versionControl boolean if version number should be prepended
 * @returns {string}
 */
export function lm (path, versionControl = true) {
	path = path.split('/');
	const filename = globalThis.js_params.language + '_' + path.pop();

	return (
		(versionControl ? '/static/v' + globalThis.js_params.version : '')
		+ path.join('/')
		+ '/'
		+ filename
	);
}

/**
 * Get the translated text for the specified translation key
 * @param {string} key the translation key
 * @param {string} str fallback string
 * @returns {string}
 */
export function l (key, str) {
	if (globalThis.js_localized?.[key]) {
		return globalThis.js_localized[key];
	}

	if (globalThis.js_params.debug) {
		EventDispatcher.dispatch('translation.missingKey', key);
		// console.log("Missing localization for: " + key);
	}

	if (typeof globalThis.localizationMissing === 'undefined') {
		globalThis.localizationMissing = {};
	}

	globalThis.localizationMissing[key] = 1;

	return str ? str : key;
}

export function lLink (key, prefixLanguage = true) {
	const link = l(key).toLowerCase().replace(/[\s_]/g, '-');

	if (prefixLanguage && globalThis.js_params.language !== globalThis.js_params.defaultLanguage) {
		// Get current language
		const language = globalThis.js_params.language;
		// Check if the first part of the url is the language
		const url = globalThis.location.pathname.split('/');
		if (url?.[1] === language) {
			return language + '/' + link;
		}
	}

	return link;
}

/** Get the translated text for the specified key inserting variables
	 @param  lkey         string - the translation key
	 @param  dictionary   string or object - the value or the dictionnary of variables name and their values to insert

	 @return the translated text **/
export function lreplace (lkey, dictionary) {
	let text = l(lkey);

	for (const key in dictionary) {
		// if key is part of prototype
		if (!dictionary.hasOwnProperty(key)) {
			continue;
		}

		text = text.replace(new RegExp('\\$' + key, 'g'), dictionary[key]);
	}

	return text;
}

/**
 *
 * @param {object} args Expected an object containing two strings: code and size
 * @returns {string} URL to agency image
 */
export function getAgencyImagePath (args) {
	let { code, size } = args;
	const { domainSpecificAgencyLogos, version } = globalThis.js_params;

	const codeSplit = code.split('_');
	let domain = 'default';

	size = size || 'regular';

	if (domainSpecificAgencyLogos) {
		if (domainSpecificAgencyLogos.includes(codeSplit[1])) {
			domain = codeSplit[0];
		}
	}

	return `/static/v${version}/images/logos_agency/${size}/${domain}/${codeSplit[1]}.png`;
}

/** Get the formatted config for the Searchbox from URL variables
	 @param  urlVars   object - the dictionary of available URL variables

	 @return the searchbox config object in the proper format to instantiate the searchbox fields **/
export function getSearchBoxConfigFromUrl (urlVars) {
	const searchBoxConfig = {};

	if ('initSearch' in globalThis.js_params && globalThis.js_params.initSearch.length != 0) {
		searchBoxConfig.legs = [];
		const initSearch = globalThis.js_params.initSearch;
		const legs = {};

		if ('from' in initSearch) {
			legs.from = initSearch.from;
			legs.fromIata = initSearch.fromIata;
			legs.fromMetro = initSearch.fromMetro;
			legs.fromIatas = [
				{
					iata: initSearch.fromIata,
					text: initSearch.from,
					isMetro: initSearch.fromMetro
				}
			];
		}

		if ('to' in initSearch) {
			legs.to = initSearch.to;
			legs.toIata = initSearch.toIata;
			legs.toMetro = initSearch.toMetro;
			legs.toIatas = [
				{
					iata: initSearch.toIata,
					text: initSearch.to,
					isMetro: initSearch.toMetro
				}
			];
		}

		if (urlVars.dateFrom) {
			legs.departDate = urlVars.dateFrom;
		}

		if (urlVars.dateTo) {
			legs.returnDate = urlVars.dateTo;
		}

		searchBoxConfig.legs.push(legs);
	}

	if ('adults' in urlVars) {
		searchBoxConfig.passengers = {};

		searchBoxConfig.passengers.adults = parseInt(urlVars.adults);
		searchBoxConfig.passengers.child = {
			count: urlVars.children ? parseInt(urlVars.children) : 0,
			ages: urlVars.childrenAges ? urlVars.childrenAges.split(',').map(str => parseInt(str)) : []
		};
	}

	// Not originating from widget searchbox
	if ('youth' in urlVars) {
		searchBoxConfig.passengers = {};

		searchBoxConfig.passengers.youth = {
			count: parseInt(urlVars.youth),
			ages: ('youthAges' in urlVars) ? urlVars.youthAges.split(',').map(str => parseInt(str)) : []
		};
	}

	return searchBoxConfig;
}

/** Deep copy an object
	 @param  object   object - the object to deep copy

	 @return a new copy of the object, returns false if object is not a string or an object
	**/
export function deepCopy (object) {
	if (typeof object === 'object') {
		return JSON.parse(JSON.stringify(object));
	}
	// if (typeof object === 'string') {
	// 	return JSON.parse(object);
	// }
	return false;
}

export const storageAvailableMap = {
	localStorage: storageAvailable('localStorage'),
	sessionStorage: storageAvailable('sessionStorage')
};

export const isLocalStorageAvailable = storageAvailable.localStorage;
export const isSessionStorageAvailable = storageAvailable.sessionStorage;


/** Stringify then set item to localStorage
	 @param  keyname   string - reference
	 @param  value   value that needs being stored in localStorage

	 @return A String, representing the inserted value
	**/
export function storedItemCreate (keyname, value, storageType = 'localStorage') {
	if (!storageAvailableMap[storageType]) {
		console.warn('LocalStorage is not available');
		return null;
	}
	if (typeof keyname !== 'string') {
		throw new TypeError('Keyname can only be of type string.');
	}
	if (value === undefined) {
		console.warn('Trying to save an undefined value to localStorage - value not stored');
		return null;
	}
	try {
		window[storageType].setItem(keyname, JSON.stringify(value));
	} catch (e) {
		console.log('Could not save item. Please, make sure localStorage is enabled for a better user experience on our website. ', e);
	}
}

/** Retrieve then parse localStorage item
	 @param  keyname   string - reference

	 @return A String, representing the value of the specified key when it exists or NULL
	 **/
export function storedItemRead (keyname, storageType = 'localStorage') {
	if (!storageAvailableMap[storageType]) {
		console.warn('LocalStorage is not available');
		return null;
	}
	// Check proper use of the function
	if (typeof keyname !== 'string') {
		throw new TypeError('Keyname can only be of type string.');
	}
	// Fetch item
	let item = window[storageType].getItem(keyname);
	if (!item) {
		return null;
	}
	try {
		item = JSON.parse(item);
	} catch (e) {
		console.warn('Compromised value stored in localStorage - removing item');
		storedItemDelete(keyname, storageType);
		return null;
	}
	return item;
}


/** Remove an item from local storage
	 @param  keyname   string - reference
	 **/
export function storedItemDelete (keyname, storageType = 'localStorage') {
	if (!storageAvailableMap[storageType]) {
		console.warn('LocalStorage is not available');
		return null;
	}
	if (typeof keyname !== 'string') {
		throw new TypeError('Keyname can only be of type string.');
	}
	try {
		window[storageType].removeItem(keyname);
	} catch (e) {
		console.log('Could not remove saved item. Please, make sure localStorage is enabled for a better user experience on our website. ', e);
	}
}

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
/**
 *
 * @param {String} type - localStorage or sessionStorage
 * @returns {Boolean} - true if available
 */
function storageAvailable (type) {
	let storage;
	try {
		storage = window[type];
		const x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		const isStorageAvailable = (
			// everything except Firefox
			e?.code === 22
			// Firefox
			|| e?.code === 1014
			// test name field too, because code might not be present
			// everything except Firefox
			|| e?.name === 'QuotaExceededError'
			// Firefox
			|| e?.name === 'NS_ERROR_DOM_QUOTA_REACHED')
			// acknowledge QuotaExceededError only if there's something already stored
			&& (storage && storage.length !== 0);
		if (!isStorageAvailable) {
			// Civet.reportError(new Error(`${type} is not available`));
		}
		return isStorageAvailable;
	}
}


/**
	 * Alias for document.querySelector
 * @param {string} selector query selector string
 * @param {Element} [scope] element to query against, defaults to document
 * @returns {Element}
 */
export function qs (selector, scope = doc) {
	return scope.querySelector(selector);
}

/**
 * Alias for document.querySelectorAll
 * @param {string} selector query selector string
 * @param {Element} [scope] element to query against, defaults to document
 * @returns {array} array of Elements
 */
export function qsa (selector, scope = doc) {
	return [...scope.querySelectorAll(selector)];
}

/**
 * Alias for document.getElementById
 * @param {string} selector query selector string
 * @param {Element} [scope] element to query against, defaults to document
 * @returns {Element}
 */
export function gebi (selector, scope = doc) {
	return scope.getElementById(selector);
}

/**
 * Alias for getElementsByClassName
 * @param {string} s query selector string
 * @param {Element} [o] element to query against, defaults to document
 * @returns {Element}
 */
export function gebcn (s, o = doc) {
	return o.getElementsByClassName(s);
}

/**
 * Sleep for a given amount of time
 * @param {number} ms number of milliseconds to sleep
 * @returns {Promise} resolves when the number of milliseconds requested has passed
 */
export function sleep (ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}


/**
 * @param {Element|String|Array} element Element to be shown or hidden
 * @param show Controls if the element should be shown or hidden
 * @returns {Element}
 */
export function showElement (element, show = true) {
	if (Array.isArray(element)) {
		return element.forEach(el => showElement(el, show));
	}
	// hideElement will call checkForElement
	if (!show) {
		return hideElement(element);
	}
	const elementReal = checkForElement(element);
	if (!elementReal) {
		console.warn('showElement: Didn\'t find element', element);
		return;
	}
	// Check the inline property
	if (elementReal.style.display === 'none') {
		elementReal.style.removeProperty('display');
	}
	// If the element is still hidden after removing the inline property
	if (isHidden(elementReal)) {
		if (!isInternetExplorer) {
			elementReal.style.display = 'block';
			// element.style.display = 'revert';
		} else {
			elementReal.style.display = 'block';
		}
	}
	return element;
}

/**
 * @param {Element|String|Array} element Element to be hidden
 * @returns {Element}
 */
export function hideElement (element, hide = true) {
	if (Array.isArray(element)) {
		return element.forEach(el => hideElement(el, hide));
	}
	if (!hide) {
		return showElement(element, false);
	}
	const elementReal = checkForElement(element);
	if (!elementReal) {
		console.warn('hideElement: Didn\'t find element', element);
		return {};
	}
	if (isHidden(elementReal)) {
		console.warn('hideElement: Element already hidden', element);
		return elementReal;
	}
	elementReal.style.display = 'none';
	return elementReal;
}

/**
 *
 * @param {Element|String|Array} element
 * @returns {boolean} true if element is visible
 */
export function toggleVisible (element) {
	if (Array.isArray(element)) {
		return element.forEach(el => toggleVisible(el));
	}

	const elementReal = checkForElement(element);

	if (!elementReal) {
		console.warn('toggleVisible: Didn\'t find element', element);
		return;
	}
	const _isHidden = isHidden(elementReal);
	if (_isHidden) {
		showElement(elementReal);
	} else {
		hideElement(elementReal);
	}
	return !_isHidden;
}

/**
 * Used internally by showElement, hideElement, slideToggle, must be given a real element and not a query selector
 * @param {Element} element
 * @returns {Boolean} true if the computed display of the element is 'none'
 */
export function isHidden (element) {
	return getComputedStyle(element).display === 'none';
}

function isElement (element) {
	return (element instanceof Element);
}

function checkForElement (element) {
	return isElement(element) ? element : qs(element);
}

/** Filter function for non iterable object based on key values
	 @param  obj   		object - object to filter within
	 @param  callback   function - filtering function to apply on key values
	 **/
export function filterObjectByKeys (obj, callback) {
	if (!obj) {
		throw new TypeError('obj must not be null');
	}
	if (typeof obj !== 'object') {
		throw new TypeError('obj must be of type array');
	}
	if (typeof callback !== 'function') {
		throw new TypeError('callback must be a function');
	}

	const objAsArray = Object.entries(obj);
	const filtered = objAsArray.filter(([key, value]) => callback(key));

	// No full support for Object.fromEntries at the moment (IE, Opera mini)
	const filteredAsObject = {};
	filtered.forEach((entry) => {
		filteredAsObject[entry[0]] = entry[1];
	});

	return filteredAsObject;
}

/**
 * Slides an element up using the Web Animations API
 * @param {Element} element Element to slide up
 * @param {number} durationInMs Number of milliseconds the animation should run for
 * @param {string} [easing = 'ease-out'] linear|ease|ease-in|ease-out|ease-in-out|step-start|step-end|steps(int,start|end)|cubic-bezier(n,n,n,n)|initial|inherit
 */
export function slideUp (element, durationInMs = 400, easing = 'ease-out') {
	element = checkForElement(element);
	if (!element) {
		console.log('slideUp: couldn\'t find element', element);
		return Promise.resolve(element);
	}
	if (element.dataset.slidUp) {
		console.log('slideUp: Element already slid up');
		return;
	}
	element.removeAttribute('data-slid-down');
	element.dataset.slidUp = true;

	const keyframes = (element) => {
		element.style.overflow = 'hidden';
		const height = element.offsetHeight + 'px';
		const { marginTop, marginBottom, paddingTop, paddingBottom } = getComputedStyle(element);
		return [
			{
				height,
				marginTop,
				marginBottom,
				paddingTop,
				paddingBottom
			},
			{
				height: 0,
				marginTop: 0,
				marginBottom: 0,
				paddingTop: 0,
				paddingBottom: 0
			}
		];
	};

	return animate(element, keyframes, durationInMs, easing).then((element) => {
		hideElement(element);
		element.style.removeProperty('overflow');
		return element;
	}).catch(() => console.log('slideUp: Animation failed'));
}

/**
 * Slides an element down using the Web Animations API
 * @param {Element} element Element to slide down
 * @param {number} durationInMs Number of milliseconds the animation should run for
 * @param {string} [easing='ease-out'] linear|ease|ease-in|ease-out|ease-in-out|step-start|step-end|steps(int,start|end)|cubic-bezier(n,n,n,n)|initial|inherit
 */
export function slideDown (element, durationInMs = 400, easing = 'ease-out') {
	element = checkForElement(element);
	if (!element) {
		console.log('slideDown: couldn\'t find element', element);
		return Promise.resolve(element);
	}
	if (element.dataset.slidDown) {
		console.log('slideDown: Element already slid down');
		return;
	}
	element.removeAttribute('data-slid-up');
	element.dataset.slidDown = true;

	const keyframes = (element) => {
		showElement(element);
		const height = element.offsetHeight + 'px';
		element.style.overflow = 'hidden';
		const { marginTop, marginBottom, paddingTop, paddingBottom } = getComputedStyle(element);

		return [
			{
				height: 0,
				marginTop: 0,
				marginBottom: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			{
				height,
				marginTop,
				marginBottom,
				paddingTop,
				paddingBottom
			}
		];
	};
	return animate(element, keyframes, durationInMs, easing).then((element) => {
		element.style.removeProperty('overflow');
		return element;
	}).catch(() => console.log('slideUp: Animation failed'));
}

/**
 * Slide and element up or down depending on it's computed display style
 * @param {Element} element element to slide
 * @returns {Promise} resolves when the element has finished animating
 */
export function slideToggle (element) {
	const elementReal = checkForElement(element);
	if (!elementReal) {
		console.log('slideToggle: couldn\'t find element', element);
		return Promise.resolve(elementReal);
	}
	if (isHidden(elementReal)) {
		return slideDown(elementReal);
	} else {
		return slideUp(elementReal);
	}
}

/**
 * Manual reset of already slid up or down element, without animation, to start over on clean slate
 * @param {Element} element element to reset
 */
export function slideReset (element) {
	element = checkForElement(element);
	if (!element) {
		console.log('slideReset: couldn\'t find element', element);
		return;
	}
	element.removeAttribute('data-slid-up');
	element.removeAttribute('data-slid-down');
}

/**
 * Fade an element in using the Web Animations API
 * @param {Element} element Element to fade in
 * @param {number} durationInMs Number of milliseconds the animation should run for
 * @param {string} [easing="ease-out"] linear|ease|ease-in|ease-out|ease-in-out|step-start|step-end|steps(int,start|end)|cubic-bezier(n,n,n,n)|initial|inherit
 * @returns {Promise} Resolves when the element has finished animating
 */
export function fadeIn (element, durationInMs = 400, easing = 'ease-out', isResultsContainer = false) {
	const elementReal = checkForElement(element);
	if (!elementReal) {
		console.log('fadeIn: couldn\'t find element', element);
		return Promise.resolve(element);
	}
	const keyframes = (elementReal) => {
		if (!isHidden(elementReal)) {
			console.log('cancelling fade in, element is already visible', elementReal);
			return [];
		}
		showElement(elementReal);
		return [
			{ opacity: 0 },
			{ opacity: 1 }
		];
	};
	return animate(elementReal, keyframes, durationInMs, easing, isResultsContainer).catch(() => ({}));
}

/**
	* Fade an element out using the Web Animations API
 * @param {Element|String} element Element to fade out
 * @param {number} durationInMs Number of milliseconds the animation should run for
 * @param {string} [easing='ease-out'] linear|ease|ease-in|ease-out|ease-in-out|step-start|step-end|steps(int,start|end)|cubic-bezier(n,n,n,n)|initial|inherit
 * @returns {Promise} Resolves when the element has finished animating
 */
export function fadeOut (element, durationInMs = 400, easing = 'ease-out', isResultsContainer = false) {
	const elementReal = checkForElement(element);
	if (!elementReal) {
		console.log('fadeOut: couldn\'t find element', element);
		return Promise.resolve(elementReal);
	}
	const keyframes = () => {
		return [
			{ opacity: 1 },
			{ opacity: 0 }
		];
	};
	return animate(elementReal, keyframes, durationInMs, easing, isResultsContainer).then(() => {
		if (!isResultsContainer) {
			hideElement(elementReal);
		}
	}).catch(() => ({}));
}

/**
 * Calls element.animate with a series of keyframes, allows the developer to force the element to be shown if it is hidden
 * @param {Element} element element to animate
 * @param {array} keyframesFunction
 * @param {number} durationInMs
 * @param {string} [easing='ease-out'] linear|ease|ease-in|ease-out|ease-in-out|step-start|step-end|steps(int,start|end)|cubic-bezier(n,n,n,n)|initial|inherit
 * @param {boolean} showOnStart if the element is hidden, force it to be shown before the animation
 * @param {boolean} hideOnComplete If the element should be shown when the animation has completed
 * @returns {Promise} resolves when the animation has completed or been cancelled
 */
let ongoingAnimations = [];
function animate (element, keyframesFunction, durationInMs = 400, easing = 'ease-out', isResultsContainer = false) {
	return new Promise((resolve, reject) => {
		const existingAnimation = ongoingAnimations.find(object => object.element === element);
		if (existingAnimation) {
			existingAnimation.animation.cancel();
			EventDispatcher.dispatch('debug:add', 'onGoingAnimations', ongoingAnimations?.length || 0);
		}

		const keyframes = keyframesFunction(element);

		if (!keyframes.length) {
			return;
		}
		const options = { duration: durationInMs, easing: easing };
		if (isResultsContainer) {
			options.fill = 'forwards';
		}
		const animation = element.animate(keyframes, options);

		const currentAnimationObject = { element: element, animation: animation };

		ongoingAnimations.push(currentAnimationObject);

		const animationFinished = () => {
			ongoingAnimations = ongoingAnimations.filter(object => object.element !== element);
			resolve(element);
		};
		animation.onfinish = animationFinished;
		animation.oncancel = animationFinished;
	});
}
// function animate (element, keyframesFunction, durationInMs = 400, easing = 'ease-out') {
// 	return new Promise((resolve, reject) => {
// 		const animationFinished = () => {
// 			ongoingAnimations = ongoingAnimations.filter(object => object.element !== element);
// 			resolve(element);
// 		};

// 		const animationCancelled = () => {
// 			ongoingAnimations = ongoingAnimations.filter(object => object.element !== element);
// 			reject(element);
// 		};

// 		const existingAnimation = ongoingAnimations.find(object => object.element === element);
// 		if (existingAnimation) {
// 			existingAnimation.animation.cancel();
// 			let keyFrames = keyframesFunction(element);
// 			const computedStyle = getComputedStyle(element);
// 			const [startKeyframe] = keyFrames;
// 			const newStartKeyframe = {};

// 			Object.keys(startKeyframe).forEach((property) => {
// 				newStartKeyframe[property] = computedStyle.getPropertyValue(property);
// 			});

// 			// Replace the start keyframe with the new one
// 			keyFrames = [newStartKeyframe, ...keyFrames.slice(1)];
// 			// // update the duration remaining relative to the new duration
// 			// const timing = existingAnimation.animation.getComputedTiming();

// 			// // duration of the running animation
// 			// const activeDuration = timing.activeDuration;

// 			// // progress between 0 and 1 of the running animation
// 			// const activeProgress = timing.progress;

// 			// // calculate duration so that velocity is constant
// 			// durationInMs -= activeDuration - activeProgress * activeDuration;


// 			// Create a new animation with the new keyframes and duration
// 			const animation = element.animate(keyFrames, { duration: durationInMs, easing: easing });
// 			ongoingAnimations.push({ element: element, animation: animation });

// 			animation.onfinish = animationFinished;
// 			animation.oncancel = animationCancelled;
// 		} else {
// 			const keyFrames = keyframesFunction(element);
// 			if (!keyFrames.length) {
// 				return;
// 			}

// 			const animation = element.animate(keyFrames, { duration: durationInMs, easing: easing });
// 			ongoingAnimations.push({ element: element, animation: animation });

// 			animation.onfinish = animationFinished;
// 			animation.oncancel = animationCancelled;
// 		}
// 	});
// }

/**
 *
 * @param {Element} element Element to find sibling of
 * @param {string} selector Selector to filter by
 * @returns {Element|null} Next sibling or null if not found
 */
export function nextElement (element, selector) {
	// Get the next element
	const nextElem = element.nextElementSibling;

	// If there's no selector, return the next element
	if (!selector) {
		return nextElem;
	}

	// Otherwise, check if the element matches the selector
	if (nextElem && nextElem.matches(selector)) {
		return nextElem;
	}

	// if it's not a match, return null
	return null;
}

/**
 * @param {Element} elem
 * @returns boolean true if width or height > 0
 */
export function isVisible (elem) {
	return !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
}

/**
 * Add or remove items with a certain value from a primitive or complex array
 * @param {array} array array to toggle items in
 * @param {string|number|object} itemToToggle item to toggle
 * @param {callback} [getValue] function that returns the value of item to toggle, only relevant for objects
 * @returns {array} a new array with the item either added or removed
 * @example
 * const myArrayOfStrings = ['Tristan', 'Valle']
 * toggleArray(myArrayOfStrings, 'Tristan') // ['Valle']
 * const myArrayOfObjects = [{name: 'Valle', age: 49}, {name: 'Tristan', age: 31}]
 * toggleArray(myArrayOfObjects, {name: 'Tristan', age: 31}, item => `${item.name}|item.age`) // [{name: 'Valle', age: 49}]
 */
export function arrayToggleValue (array, itemToToggle, getValue = item => item) {
	const index = array.findIndex(item => getValue(item) === getValue(itemToToggle));
	if (index === -1) {
		return [...array, itemToToggle];
	}
	return removeAtIndex(array, index);
}

export function removeAtIndex (array, index, numberOfItemsToRemove = 1) {
	const copy = [...array];
	copy.splice(index, numberOfItemsToRemove);
	return copy;
}

/**
 * Encode a set of form elements as an array of names and values.
 * @param {Element} form
 * @returns {Array}
 */
export function serializeArray (form) {
	let field; let l; const s = [];
	if (typeof form === 'object' && form.nodeName === 'FORM') {
		const len = form.elements.length;
		for (let i = 0; i < len; i++) {
			field = form.elements[i];
			if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button') {
				if (field.type === 'select-multiple') {
					l = form.elements[i].options.length;
					for (let j = 0; j < l; j++) {
						if (field.options[j].selected) {
							s[s.length] = { name: field.name, value: field.options[j].value };
						}
					}
				} else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
					s[s.length] = { name: field.name, value: field.value };
				}
			}
		}
	}
	return s;
}

/**
 * Does what it says on the box ^_^
 * @param {any} variableToCheck
 * @returns {boolean}
 */
export function isEmptyObject (variableToCheck) {
	return Object.keys(variableToCheck).length === 0 && variableToCheck.constructor === Object;
}

/**
 * Does what it says on the tin =]
 * @param {callback} callback
 * @param {Number} wait
 * @returns
 */
export function debounce (callback, wait) {
	let timeout;
	return (...args) => {
		const context = this;
		clearTimeout(timeout);
		timeout = setTimeout(() => callback.apply(context, args), wait);
	};
}


export function getOffset (element) {
	const elementReal = checkForElement(element);
	if (!elementReal) {
		console.warn('offset: Didn\'t find element', element);
		return;
	}
	const rect = elementReal.getBoundingClientRect();
	return {
		top: rect.top + globalThis.pageYOffset - document.documentElement.clientTop,
		left: rect.left + globalThis.pageXOffset - document.documentElement.clientLeft
	};
}

/**
 * Get the last element of an array or null
 * @param {array} array
 * @returns {any|null} last element of the array
 */
export function last (array) {
	return array?.[array.length - 1] || null;
}

export function addFilteredEventListener (targetSelector, eventType, closestSelector, callback) {
	const elementReal = checkForElement(targetSelector);
	if (!elementReal) {
		console.warn('addFilteredEventListener: Didn\'t find element', targetSelector);
		return;
	}
	elementReal.addEventListener(eventType, (event) => {
		const { target, stopPropagation, preventDefault } = event;
		const closest = event.target.closest(closestSelector);
		if (closest) {
			callback({ target, stopPropagation, preventDefault, closest });
		}
	});
}
/**
 *
 * @param {Object} filterObject object to clean
 * @param {array} keyWhitelist a series of keys to keep even if they are empty
 * @returns {Object}
 */
export function removeEmptyEntriesFromObject (filterObject, keyWhitelist = []) {
	// Get only the keys we are interested in
	const filterKeys = Object.keys(keyWhitelist.length ? keyWhitelist : filterObject);

	// Create a clean object that will contain the filter keys
	const cleanObject = {};

	// Loop over all entries in the store, filter the keys we are interested in
	Object.entries(filterObject).filter(([key]) => filterKeys.includes(key)).forEach(([key, value]) => {
		// If not an empty array/object then add it to `cleanObject`
		if (!isVariableEmpty(value)) {
			cleanObject[key] = value;
		}
	});
	return cleanObject;
}

export function isVariableEmpty (value) {
	const typeOfValue = typeof value;
	switch (typeOfValue) {
		case 'boolean':
		case 'string':
		case 'number':
			return false;
		case 'object':
			if (value === null) {
				return false;
			} else if (Array.isArray(value) && value.length > 0) {
				return false;
			} else if (Object.keys(value).length > 0) {
				return false;
			}
			return true;
		default:
			return true;
	}
}

/**
 * Attach the intersection observer to an element
 * Run a callback when that element scrolls into view
 * @param {Element} element dom node - the element to bind to
 * @param {callback} callback function to call when the element scrolls into view
 * @param {object} intersectionOptions options to pass to the intersection observer
 * @return none
 */
export function bindIntersectionObserver (element, callback, intersectionOptions) {
	const observer = new IntersectionObserver(callback, intersectionOptions);
	observer.observe(element);
}

export const documentReady = (callbackFunc) => {
	if (!document.addEventListener) {
		document.attachEvent('onreadystatechange', function () {
			if (document.readyState === 'complete') {
				callbackFunc();
			}
		});
	} else if (document.readyState === 'loading') {
		document.addEventListener('DOMContentLoaded', callbackFunc);
	} else {
		callbackFunc();
	}
};

/**
 * @param {object} properties
 * @param {object} attribute
 * @param {string} text - optional source code
 * @return none
 */
export function loadJavascript (properties, attributes) {
	const script = document.createElement('script');

	// Set the properties
	for (const key in properties) {
		script[key] = properties[key];
	}

	// Set the attributes
	for (const key in attributes) {
		script.setAttribute(key, attributes[key]);
	}

	document.head.appendChild(script);
}

/**
 * @param {string} urlParameterName
 * @param {string} url
 * @param {any} defaultValue
 * @returns
 */
export function getUrlVarBase64 (urlParameterName, url = globalThis.location.href, defaultValue = {}) {
	let resp = defaultValue;
	try {
		const urlVars = new URLSearchParams(location.search).get(urlParameterName);
		resp = JSON.parse(Base64.decode(decodeURIComponent(urlVars)));
	} catch (error) {
		resp = defaultValue;
	}
	return resp;
}

/**
 *
 * @param {Element|String} element
 * @param {String} property
 * @param {any} value
 */
export function propertySet (element, property, value) {
	const elementActual = checkForElement(element);
	if (!elementActual) {
		console.warn('Could not find:', element);
		return;
	}
	elementActual[property] = value;
}

/**
 *
 * @param {String} str
 * @returns {String}
 */
export function removeDotFromString (str) {
	return str.replace(/\./g, '');
}

/**
 * Generates a psuedo random string
 * @param {Number} length - Max length 15
 * @returns {String}
 */
export function genUID (length = -8) {
	return Math.random().toString(16).slice(length);
}

/**
 * Checks an objets messages matches a set of strings
 * @param {Error} error
 * @returns {Boolean}
 */
export function isNetworkError (error) {
	const networkErrorStrings = ['NetworkError', 'Load failed', 'Failed to fetch'];
	return networkErrorStrings.some(networkErrorString => error?.message?.includes?.(networkErrorString));
}
export function classList (element) {
	const elementReal = checkForElement(element)?.classList;
	if (!elementReal) {
		console.warn('classList: Didn\'t find element', element);
	}

	return {
		toggle: function (className, force) {
			if (force !== undefined) {
				elementReal?.toggle(className, !!force);
			} else {
				elementReal?.toggle(className);
			}
			return this;
		},
		add: function (...classNames) {
			elementReal?.add(...classNames);
			return this;
		},
		remove: function (...classNames) {
			elementReal?.remove(...classNames);
			return this;
		},
		contains: function (className) {
			return elementReal?.contains(className);
		}
	};
}


/**
 * @param {Object} element
 * @returns {Boolean}
 */
export function isError (obj) {
	return Object.prototype.toString.call(obj) === '[object Error]';
}

export function debugTranslationKeys (isVisible) {
	if (isVisible) {
		setCookie('showTranslationKeys', isVisible);
	} else {
		eraseCookie('showTranslationKeys');
	}
	location.reload();
}

export function overrideTranslationFunctions () {
	// eslint-disable-next-line no-func-assign
	l = key => key;
	// eslint-disable-next-line no-func-assign
	lm = (key, count) => key;
	// eslint-disable-next-line no-func-assign
	lreplace = (key, replace) => key + ' - ' + JSON.stringify(replace);
}

export function isAbortError (error) {
	return error?.name === 'AbortError';
}

export function decimalToBinary (dec) {
	return (dec >>> 0).toString(2);
}

/**
 *
 * @param {Number} start
 * @returns {Number}
 */
export function timeExecution (start) {
	if (!start) {
		return Date.now();
	}
	return Math.round(Date.now() - start);
}

/**
 * Useful for sending data to the server, especially in unusual circumstances like beforeunload
 * @param {String} target
 * @param {String} method
 * @param {Array} parameters
 */
export function sendBeacon (target, method, parameters) {
	// if (typeof window?.navigator?.sendBeacon !== 'function') {
	// 	navigator.sendBeacon(
	// 		`${globalThis.location.protocol}//${globalThis.location.hostname}/conapi`,
	// 		JSON.stringify({
	// 			class: target,
	// 			method,
	// 			arguments: parameters
	// 		})
	// 	);
	// 	return;
	// }
	ConnectApi(target, method, parameters);
}

/**
 * @description Converts an object to a query string
 * @param {Object} obj
 * @returns
 */
export function objectToQueryString (obj) {
	return Object.keys(obj).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])).join('&');
}


export function getElementXPath (element) {
	if (!element) {
		return null;
	}

	if (element.id) {
		return `//*[@id=${element.id}]`;
	} else if (element.tagName === 'BODY') {
		return '/html/body';
	} else {
		const sameTagSiblings = Array.from(element.parentNode.childNodes)
			.filter(e => e.nodeName === element.nodeName);
		const idx = sameTagSiblings.indexOf(element);

		return getElementXPath(element.parentNode)
      + '/'
      + element.tagName.toLowerCase()
      + (sameTagSiblings.length > 1 ? `[${idx + 1}]` : '');
	}
}


export function lerp (x, y, a) {
	return x * (1 - a) + y * a;
}

/**
 * Add event listeners to an array of elements
 * @param {Element[]} els 	array of elements
 * @param {String} ev		event
 * @param {Function} fn		function
 * @param {Object} options	object with options for the addEventListener
 * return none
 */
export function addEventListeners (els, ev, fn, options = false) {
	if (Array.isArray(els)) {
		els.forEach((el) => {
			if (isElement(el)) {
				el.addEventListener(ev, fn, options);
			} else {
				console.warn('addEventListeners: element incorrect');
			}
		});
	} else {
		console.warn('addEventListeners: array of elements is incorrect');
	}
}

export function encodeFilters (filters) {
	return Base64.encode(JSON.stringify(filters));
}

export function roundToNearest (num, nearest) {
	return Math.round(num / nearest) * nearest;
}
class Toaster {
	constructor () {
		this.toast = new Toast();
	}

	/**
	 *
	 * @param {string} message - message to display
	 * @param {string} type - [success|warning|error|alert|dark]
	 * @param {*} display - [top|bot]
	 */
	newToast (message, type, display) {
		this.toast.message = message;
		this.toast.type = type;
		this.toast.display = display;
		this.toast.assign();
	}
}

export const toaster = new Toaster();

export function shareClick (shareData, disabledOnDesktop = true) {
	if (disabledOnDesktop && isDesktopView()) {
		return Promise.reject(new Error('Sharing disabled on desktop'));
	}
	if (navigator.canShare && navigator.canShare(shareData)) {
		return navigator.share(shareData);
	} else {
		return Promise.reject(new Error('Cannot share'));
	}
}


export function copyToClipboard (textToCopy) {
	if (navigator.clipboard) {
		return navigator.clipboard.writeText(textToCopy);
	}
	// Fallback for browsers without navigator.clipboard API
	return new Promise((resolve, reject) => {
		const textArea = document.createElement('textarea');
		textArea.value = textToCopy;
		textArea.style.position = 'fixed'; // Avoid scrolling to bottom
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			const successful = document.execCommand('copy');
			document.body.removeChild(textArea);
			successful ? resolve() : reject(new Error('Failed to copy text to clipboard'));
		} catch (err) {
			document.body.removeChild(textArea);
			reject(err);
		}
	});
}

/**
 * Used for logging events
 * @param {string} event - This should be a verb
 * @param {string} eventMeta - This should be a noun
 * @returns {void}
 */
export function logEvent (event, eventCategory, meta = '') {
	if (event) {
		// In case we need to buffer the sendBeacons in the future
		// logInterval({ eventName: event, category: eventCategory, meta, pageClass: js_params.analyticsPageClass, pageName: js_params.analyticsPageName });
		sendBeacon('Analytics', 'logEvent', [[{ event, eventCategory, meta, pageClass: js_params.analyticsPageClass, pageName: js_params.analyticsPageName }]]);
		console.debug({ eventName: event, category: eventCategory, meta, pageClass: js_params.analyticsPageClass, pageName: js_params.analyticsPageName });
	}
}

/**
 * @param {Array} events
 * @returns {void}
 */
export function logEvents (events) {
	events = events.map(event => ({ ...event, pageClass: js_params.analyticsPageClass, pageName: js_params.analyticsPageName }));
	sendBeacon('Analytics', 'logEvent', events);
	console.debug(events);
}

if (isBrowser) {
	window.eventCart = [];
}

/**
 * Begin an interval that logs events periodically
 * @param {Array} events
 * @returns {void}
 */
export function logInterval (event) {
	window.eventCart.push(event);

	if (!window.eventInterval) {
		window.eventInterval = setInterval(() => {
			if (window.eventCart.length) {
				logEvents(window.eventCart);
				window.eventCart = [];
			}
		}, 3000);
	}
}

let loadedGoogleMaps = false;
const googleMapsCallbacks = [];


// Global function to be called once the Google Maps script is loaded
function onGoogleMapsLoaded () {
	loadedGoogleMaps = true;
	// Execute all registered callbacks
	googleMapsCallbacks.forEach((callback) => {
		if (typeof callback === 'function') {
			callback();
		}
	});
}

window.onGoogleMapsLoaded = onGoogleMapsLoaded;

export function initGoogleMaps (googleApiKey, callback = () => {}) {
	// Check if already loaded
	if (loadedGoogleMaps) {
		callback();
		return;
	}

	// Register the callback
	googleMapsCallbacks.push(callback);

	// Load the script if not already loading
	if (!gebi('googleMapsScript')) {
		const { language } = js_params;
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&language=${language}&callback=onGoogleMapsLoaded`;
		script.id = 'googleMapsScript';
		document.body.appendChild(script);
	}
}
