// connectApi -> creates parameters
// post -> creates fetch options
// abortableFetch -> creates a thenable object
import { lLink } from '@js/utils/toolbox';

/**
 *
 * @param {string} target
 * @param {string} method
 * @param {string} parameters
 * @param {Function} callback
 * @param {Function} onError
 * @returns {Promise}
 */
export function ConnectApi (target, method, parameters) {
	const endpoint = lLink('conapi');
	const baseUrl = window.location.origin;
	// const baseUrl = js_params?.domainRelative ? `${window.location.protocol}//${js_params.domainRelative}` : window.location.origin;
	const url = `${baseUrl}/${endpoint}`;
	if (!Array.isArray(parameters)) {
		console.warn('arguments must be an array', parameters);
		parameters = [parameters];
	}
	const body = { class: target, method, arguments: parameters };
	return post(url, body);
}

if (typeof window !== 'undefined') {
	window.ConnectApi = ConnectApi;
}

/**
 *
 * @param {String} url
 * @param {Object} options
 * @param {String} method
 * @returns
 */
function networkRequest (url, options, method = 'POST') {
	const body = JSON.stringify(options);
	return new AbortableFetch(url, {
		method: method,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: body
	}).then(response => unwrapResponseData(response, body));
}

/**
 *
 * @param {string} url
 * @param {object|string} options
 * @returns {Promise}
 */
function post (url, options) {
	return networkRequest(url, options, 'POST');
}

/**
 *
 * @param {string} url
 * @param {object|string} options
 * @returns {Promise}
 */
export function get (url, options) {
	return networkRequest(url, options, 'GET');
}

/**
 *
 * @param {Response} response
 * @returns {string|object}
 */
function unwrapResponseData (response, connectApiParameters) {
	if (!response.ok) {
		const error = new Error(`ConnectApi fail: status - ${response?.statusText}, status code - ${response?.status}`);
		const headers = {};
		try {
			response.headers.forEach((value, key) => {
				headers[key] = value;
			});
		} catch (tryCatchError) {
			console.log('Error in headers.forEach', tryCatchError);
		}
		const headersLength = JSON.stringify(headers).length;
		error.connectApiParameters = JSON.stringify({ connectApiParameters, headersLength, headers });
		console.log(error.connectApiParameters);
		return Promise.reject(error);
	}


	const contentType = response.headers.has('content-type')
		? response.headers.get('content-type')
		: '';

	const TYPE_JSON = new RegExp('^application/(x-)?json', 'i');
	const TYPE_TEXT = new RegExp('^text/', 'i');
	if (contentType === null) {
		return Promise.resolve(null);
	} else if (TYPE_JSON.test(contentType)) {
		return response.json();
	} else if (TYPE_TEXT.test(contentType)) {
		return response.text();
	} else {
		return (response.blob());
	}
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/await#thenable_objects
class AbortableFetch {
	constructor (uri, options) {
		this.ctrl = new AbortController();
		this._native = window.fetch(uri, {
			...options || {},
			signal: this.ctrl.signal
		});
	}

	then (fn) {
		this._native = this._native.then(fn);
		return this;
	}

	catch (fn = data => data) {
		this._native = this._native.catch((err) => {
			if (typeof fn !== 'function') {
				console.warn('callback must be a function');
				return;
			}
			if (err.name !== 'AbortError') {
				console.trace('Error: ', err);
				return fn(err);
			}
		});
		return this;
	}

	finally (fn) {
		this._native = this._native.finally(fn);
		return this;
	}

	abort () {
		this.ctrl.abort();
	}
}

// export class AbortError extends Error {
// 	constructor(message = 'Aborted') {
// 		super(message);
// 		this.name = 'AbortError';
// 	}
// }
// // AbortablePromise is a subclass of Promise that implements Abortable interface
// export class AbortablePromise extends Promise {
// 	// Constructor, note we can provide 3 args: resolve, reject, abortSignal
// 	constructor(executor) {
// 		const abortController = new AbortController();
// 		const abortSignal = abortController.signal;
// 		// This is the executor function to be passsed to the superclass - Promise
// 		const normalExecutor = (resolve, reject) => {
// 			abortSignal.addEventListener('abort', () => {
// 				reject(new AbortError());
// 			});
// 			executor(resolve, reject, abortSignal);
// 		};
// 		super(normalExecutor);
// 		// Bind the abort method
// 		this.abort = abortController.abort;
// 	}
// }
